<template>
	<el-dialog :title="!dataForm.recNo ? '新增文件审查记录' : '更新文件审查记录'" :close-on-click-modal="false" :visible.sync="visible"
		width="1050px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true" class="border-form"
			style="margin-right: 70px;">
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="文件类型" class="item" prop="fileType">
						<el-select class="sp-input1" v-model="dataForm.fileType" placeholder="请选择" :disabled="true">
							<el-option v-for="item in documentTypeList" :key="item.displayValue" :label="item.displayName"
								:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="文件名称" class="item" prop="fileName">
						<el-input class="sp-input1" v-model="dataForm.fileName" placeholder="文件名称" clearable>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="送审单位" class="item" prop="examineCompany">
						<el-input class="sp-input" v-model="dataForm.examineCompany" placeholder="送审单位" clearable>
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="接收时间" class="item" prop="receiveTime">
						<el-date-picker class="sp-input" value-format="yyyy-MM-dd" clearable v-model="dataForm.receiveTime"
							type="date">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="接收人" class="item" prop="receiveMan">
						<el-input class="sp-input" v-model="dataForm.receiveMan" placeholder="接收人" clearable>
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="份数" class="item" prop="fileNumber">
						<el-input class="sp-input" v-model="dataForm.fileNumber" placeholder="份数" clearable>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<!-- <el-col :span="12">
					<el-form-item label="审查状态">
						<el-select v-model="dataForm.examineStatus" placeholder="请选择" clearable>
							<el-option v-for="item in examineList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col> -->
				<el-col :span="12" class="col-set_border">
					<el-form-item label="审查人" prop="examineMan" class="item">
						<el-input class="sp-input" v-model="dataForm.examineMan" placeholder="审查人" clearable>
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="审查时间" prop="examineTime" class="item">
						<el-date-picker class="sp-input" value-format="yyyy-MM-dd" v-model="dataForm.examineTime"
							type="date" clearable>
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="审查意见" prop="examineSuggestion" class="item">
						<el-input class="sp-input1" v-model="dataForm.examineSuggestion" placeholder="审查意见" clearable>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="接受时间" prop="acceptTime" class="item">
						<el-date-picker class="sp-input" value-format="yyyy-MM-dd" v-model="dataForm.acceptTime" type="date"
							clearable>
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="接受状态" prop="acceptFlg" class="item">
						<el-select class="sp-input" v-model="dataForm.acceptFlg" placeholder="请选择" clearable>
							<el-option v-for="item in acceptList" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="返回时间" prop="returnTime" class="item">
						<el-date-picker class="sp-input" value-format="yyyy-MM-dd" v-model="dataForm.returnTime" type="date"
							:clearable="true">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="返回接收人" prop="returnReceiveMan" class="item">
						<el-input class="sp-input" v-model="dataForm.returnReceiveMan" placeholder="返回接收人" clearable>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row style="margin-top: 20px;">
				<el-col :span="7">
					<el-upload ref="uploadScanFile" class="upload-demo" action="action" drag :http-request="uploadScanFile"
						multiple :show-file-list=false :auto-upload="true" style="margin-left: 0px;">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					</el-upload>
				</el-col>
				<el-col :span="17">
					<el-table v-if="dataForm.files && dataForm.files.length > 0" :data="dataForm.files"
						:cell-style="{ padding: '0px' }" border>
						<el-table-column type="index" label="No." width="50px" align="center">
						</el-table-column>
						<el-table-column prop="fileName" label="文件名称" align="center" width="496px">
							<template slot-scope="scope">
								<el-input v-model="dataForm.files[scope.$index].fileName" placeholder="请填写"
									class="fileinput"></el-input>
							</template>
						</el-table-column>
						<el-table-column label="" width="105px" align="center">
							<template slot-scope="scope">
								<el-button type="text" @click="delFile(scope.$index)">删除</el-button>
								<el-button type="text"
									@click="downloadFile(scope.row.fileUri, scope.row.fileName)">查看</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
		</el-form>

		<el-row class="formBtnGroup">
			<!-- <el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button> -->
			<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "examination-add-or-update",
	data() {
		return {
			visible: false,
			inline: false,
			documentTypeList: [],
			action: "",
			dataForm: {
				recNo: '',
				fileName: '',
				examineCompany: '',
				receiveTime: '',
				receiveMan: '',
				fileType: '',
				examineTime: '',
				returnTime: '',
				acceptTime: '',
				acceptFlg: '',
				examineSuggestion: '',
				returnStatus: '',
				returnReceiveMan: '',
				files: [],
				fileNumber: '',
				loadFileName: '',
				examineMan: '',
				fileUri: '',
			},
			examineList: [{
				value: '0',
				label: '审查中'
			}, {
				value: '1',
				label: '已审查'
			}],
			acceptList: [{
				value: '0',
				label: '未接受'
			}, {
				value: '1',
				label: '已接受'
			}],
			returnList: [
				{
					value: "0",
					label: "未返回"
				},
				{
					value: "1",
					label: "返回"
				}
			],
			dataRule: {
				fileType: [
					{ required: true, message: "请选择文件类型", trigger: "change" }
				],
				fileName: [
					{ required: true, message: "请输入文件名称", trigger: "blur" }
				],
				examineCompany: [
					{ required: true, message: "请输入送审单位", trigger: "blur" }
				],
				receiveTime: [
					{ required: true, message: "请选择接收时间", trigger: "change" }
				],
				receiveMan: [
					{ required: true, message: "请输入接收人", trigger: "blur" }
				],
				fileNumber: [
					{ required: true, message: "请输入份数", trigger: "blur" }
				],
			},
		}
	},
	methods: {
		getCurrentTime() {
			//获取当前时间并打印
			var _this = this;
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let hh = new Date().getHours();
			let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
			let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
			return yy + '-' + mm + '-' + dd;
		},
		init(id, fileType) {
			this.visible = true;
			this.dataForm = {
				recNo: '',
				fileName: '',
				examineCompany: '',
				receiveTime: '',
				receiveMan: '',
				fileType: '',
				examineTime: '',
				returnTime: '',
				acceptTime: '',
				acceptFlg: '',
				examineSuggestion: '',
				returnStatus: '',
				returnReceiveMan: '',
				fileNumber: '',
				files: [],
				loadFileName: '',
				fileUri: '',
				examineMan: '',
			};
			this.dataForm.recNo = id ? id : 0;
			this.dataForm.receiveMan = $common.getItem("userName");
			this.dataForm.receiveTime = this.getCurrentTime()
			// this.dataForm.fileType = fileType
			this.getDocumentTypeList();
			this.$nextTick(() => {
				this.$refs["dataForm"].resetFields();
				this.$set(this.dataForm, 'fileType', fileType)
				if (this.dataForm.recNo) {
					this.$http({
						url: this.$store.state.httpUrl + `/business/fileexamineinfo/info/${this.dataForm.recNo}`,
						method: 'get',
						params: this.$http.adornParams({
							recNo: this.dataForm.recNo
						})
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.dataForm = data.body;
						}
					})
				}
			})
		},
		//审查文件类型
		getDocumentTypeList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubmitDocumentType/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.documentTypeList = data.body;
				}
			});
		},
		uploadScanFile(param) {
			if (!this.dataForm.fileType) {
				this.$message.error('请选择文件类型之后再进行文件上传');
			} else {
				const formData = new FormData();
				formData.append('file', param.file);
				this.$refs.uploadScanFile.clearFiles();
				this.$http.post(
					this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/20/" + this.dataForm.fileType,
					formData,
					{
						headers: { 'Content-Type': 'multipart/form-data' }
					},
				).then(res => {
					res = res.data;
					if (res.resultCode === 200) {
						let file = {
							fileName: this.extractFileName(res.body.fileName),
							filePath: res.body.fileSavePath,
							fileUri: res.body.fileUri,
							recNo: ''
						}
						this.dataForm.files.push(file);
					} else {
						this.$message.info('文件上传失败')
					}
				})
			}
		},
		extractFileName(fileName) {
			let index = fileName.lastIndexOf(".");
			return fileName.substr(0, index)
		},
		delFile(index) {
			this.dataForm.files.splice(index, 1)
		},
		downloadFile(fileUri, filename) {
			filename = this.getSuffix(fileUri, filename)
			this.getBlob(fileUri, (blob) => {
				this.saveAs(blob, filename)
			})
		},
		getSuffix(url, filename) {
            let lastIndex = url.lastIndexOf(".");
            return filename + url.substr(lastIndex)
        },
		getBlob(url, cb) {
			var xhr = new XMLHttpRequest()
			xhr.open('GET', url, true)
			xhr.responseType = 'blob'
			xhr.onload = function () {
				if (xhr.status === 200) {
					cb(xhr.response)
				}
			}
			xhr.send()
		},
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename)
			}
			else {
				var link = document.createElement('a')
				var body = document.querySelector('body')

				link.href = window.URL.createObjectURL(blob)
				link.download = filename

				link.style.display = 'none'
				body.appendChild(link)

				link.click()
				body.removeChild(link)
				window.URL.revokeObjectURL(link.href)
			}
		},
		dataFormSubmit() {
			if (this.dataForm.fileName == null || this.dataForm.fileName == "") {
				this.$message.error("请填写文件名称");
				return;
			}
			if (this.dataForm.examineCompany == null || this.dataForm.examineCompany == "") {
				this.$message.error("请填写送审单位");
				return;
			}
			if (this.dataForm.receiveTime == null || this.dataForm.receiveTime == "") {
				this.$message.error("请填写接收时间");
				return;
			}
			if (this.dataForm.receiveMan == null || this.dataForm.receiveMan == "") {
				this.$message.error("请填写接收人");
				return;
			}
			if (this.dataForm.fileNumber == null || this.dataForm.fileNumber == "") {
				this.$message.error("请填写份数");
				return;
			}
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					// 审查时间为空，审查状态为审查中；审查时间录入，则为已审查
					if (this.dataForm.examineTime != null && this.dataForm.examineTime != '') {
						this.dataForm.examineStatus = '1'
					} else {
						this.dataForm.examineStatus = '0'
					}

					let _url = "/business/fileexamineinfo/save";
					if (this.dataForm.recNo && this.dataForm.recNo !== 0) {
						_url = "/business/fileexamineinfo/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData({
							workNo: $common.getItem("workNo"),
							subjectNo: $common.getItem("subjectNo"),
							examineCompany: this.dataForm.examineCompany,
							fileName: this.dataForm.fileName,
							fileType: this.dataForm.fileType,
							recNo: this.dataForm.recNo,
							receiveMan: this.dataForm.receiveMan,
							receiveTime: this.dataForm.receiveTime,
							files: this.dataForm.files,
							fileNumber: this.dataForm.fileNumber,
							examineMan: this.dataForm.examineMan,
							examineStatus: this.dataForm.examineStatus,
							examineSuggestion: this.dataForm.examineSuggestion,
							examineTime: this.dataForm.examineTime,
							acceptFlg: this.dataForm.acceptFlg,
							acceptTime: this.dataForm.acceptTime,
							returnTime: this.dataForm.returnTime,
							returnReceiveMan: this.dataForm.returnReceiveMan,
						})
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$parent.getDataList();
								}
							});
						}
					});
				}
			});
		}

	}
}
</script>
<style scoped>
.el-dialog-div {
	height: 62vh;
	overflow-x: hidden;
}

.fileinput /deep/ .el-input__inner {
	width: 100% !important;
}

.upload-demo /deep/ .el-upload-dragger {
	width: 220px;
	height: 125px;
}

.upload-demo /deep/ .el-upload-dragger .el-icon-upload {
	font-size: 40px;
	margin: 20px 0px 5px 0px;
	line-height: 40px;
	/* padding-top: 15px */
}

.upload-demo /deep/ .el-upload-dragger .el-upload__text {
	line-height: 20px;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

.border-form .el-row.set_border .col-set_border {
    border-right:solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.sp-input /deep/ .el-input__inner {
	width: 290px !important;
}

.sp-input1 /deep/ .el-input__inner {
	width: 750px !important;
}
</style>
